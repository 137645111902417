import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../../../../../assets/logos/DeLuxMintLogo.png";
import { Link } from "react-router-dom";
import { Typography, Menu, MenuItem } from "@mui/material";
import Login from "../../../../features/Login/login";
import MenuIcon from "@mui/icons-material/Menu";
import {
  useMediaQuery,
  TextField,
  Modal,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import CloseIcon from "@mui/icons-material/Close";

import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark"; // Example icon for Collection Results
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // Example icon for NFT Results
import PeopleIcon from "@mui/icons-material/People"; // Example icon for User Results

import CollectionsIcon from "@mui/icons-material/Collections"; // Icon for Collection Results
import PersonIcon from "@mui/icons-material/Person"; // Icon for User Results

import MediaCard from "../../../../features/CategorryCard/Card";

import { CloseOutlined } from "@mui/icons-material";

import { axiosRequestAuth } from "../../../../../services/api/api";

import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import SearchCard from "../../../../shared/searchCard/searchCard";
import CustomLoader from "../../../../shared/customLoader/customLoader";
import { useSelector } from "react-redux";

const drawerStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the background color to black
  color: "white", // Set the text color to white
  transition: "background-color 0.3s",
  paper: {
    background: "black",
    color: "white",
  },
};

const activeOptionStyle = {
  color: "purple",
};

const submenuStyle = {
  overflow: "hidden",
  transition: "max-height 0.5s ease-in-out", // Adjust animation speed here
  maxHeight: "0",
};

const openSubmenuStyle = {
  maxHeight: "1000px", // Adjust this value based on your content's height
};

function CustomDrawer() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });
  const is1024ScreenSize = useMediaQuery("(min-width:1024px)");
  // const [activeTab, setActiveTab] = React.useState(0);

  // const handleTabClick = (index) => {
  //   setActiveTab(index);
  //   // Handle tab click logic here
  // };
  const [openExplore, setOpenExplore] = React.useState(false);
  const [openAccount, setOpenAccount] = React.useState(false);
  const [exploreAnchorEl, setExploreAnchorEl] = useState(null);
  const [packsAnchorEl, setPacksAnchorEl] = useState(null);

  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [claimPacksEl, setclaimPacksEl] = useState(null);

  const [adminAnchorEl, setAdminAnchorEl] = useState(null);

  const [nfts, setNfts] = useState([]);
  const [collection, setCollection] = useState([]);

  const [isFeatured, setIsFeatured] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState();
  let txtcolor = "white";
  const toggleDrawer = (anchor, open) => (event) => {
    console.log("Event: ", event);

    // Check if the event is provided and meets the condition
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const toggleExplore = () => {
    setOpenExplore(!openExplore);
  };

  const toggleAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleExploreClick = (event) => {
    setExploreAnchorEl(event.currentTarget);
  };
  const handlePacksClick = (event) => {
    setPacksAnchorEl(event.currentTarget);
  };

  const handleExploreClose = () => {
    setExploreAnchorEl(null);
  };
  const handlePacksClose = () => {
    setPacksAnchorEl(null);
  };
  const handleUSDtoXRPClick = () => {
    navigate("/usd-collections");
    handleExploreClose();
    toggleDrawer("left", false)();
  };

  const handlePackNftsClick = () => {
    navigate(
      "/category/Lux%20Lions%20King%20of%20The%20Jungle%20Game?fromPacks=true"
    );
    handlePacksClose();
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleCollectionsClick = () => {
    navigate("/xrp-collections");
    handleExploreClose();
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleCollectionsGameClick = () => {
    navigate("/game-collections");
    handleExploreClose();
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleNavigationForSale = () => {
    navigate("/recently-for-sale");
    handleExploreClose();
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleBuyPacks = () => {
    navigate(
      "/category/Lux%20Lions%20Pack%20Opening%20Potions?fromBuyPacks=true"
    );
    handlePacksClose();
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleCampaignsClick = () => {
    navigate("/packs");
    handlePacksClose();
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleAccountClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };

  const handleClaimPacksClick = (event) => {
    setclaimPacksEl(event.currentTarget);
  };

  const handleClaimPacksClose = () => {
    setclaimPacksEl(null);
  };

  const handleNavigationMyNfts = () => {
    handleAccountClose();
    navigate("/nfts/myNFTs");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleAdminClose = () => {
    setAdminAnchorEl(null);
  };

  const handleNavigationMinting = () => {
    handleAccountClose();
    navigate("/nfts/myListedNFTs");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleNavigationCategory = () => {
    handleAccountClose();
    navigate("/admin/categories");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleAdminClick = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleNavigationCollection = () => {
    handleAdminClose();
    navigate("/collections");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleNavigationAdmin = () => {
    handleAdminClose();
    navigate("/admins");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleNavigationAdminFeatured = () => {
    handleAdminClose();
    navigate("/admin/featured");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleBulkMintDetail = () => {
    handleAdminClose();
    navigate("/bulk-mint-detail");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleNavigationCarousel = () => {
    handleAccountClose();
    navigate("/admin/carousel");
    toggleDrawer("left", false)(); // Close the drawer
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      GetSearch();
      setOpenModal(true);
    }
  };

  async function GetSearch() {
    let acc = localStorage.getItem("accnum");
    setLoading(true);

    try {
      const response = await axiosRequestAuth("post", "market/search", {
        searchQuery: query,
      });
      setNfts(response?.data?.data?.nfts);

      setCollection(response?.data?.data?.collections);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //  toast.error(error.response.data.message);
    }
  }

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleClose = (e) => {
    setOpenModal(false);
  };

  const handleData = (e) => {
    setOpenModal(false);
    // useDispatch(setCollectionId())
    navigate(`/category/${e.id}`);
  };

  // const isAuthenticated = localStorage.getItem("token");
  const {
    token: isAuthenticated,
    allCounts,
    isAdmin,
  } = useSelector((state) => state.userNft);

  console.log("isAuthenticated", isAuthenticated);

  return (
    <>
      <Box>
        <Box
          className="ff"
          sx={{ height: "4.5rem", display: "flex", alignItems: "center" }}
        >
          <Button onClick={toggleDrawer("left", true)}>
            <MenuIcon sx={{ color: "white" }} />
          </Button>
        </Box>

        <Drawer
          // style={{ }}
          PaperProps={{
            sx: {
              width: "250px", // Adjust this value to your desired width
              background: "rgba(0,0,0,100)",
              color: "white",
              // height: "100vh",
            },
          }}
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
          sx={drawerStyle}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Logo}
              height={85}
              className="pointer-on-hover"
              style={{ paddingBottom: 6, paddingTop: 10 }}
              onClick={() => {
                navigate("/");
                toggleDrawer("left", false)(); // Close the drawer
              }}
            />
          </Box>

          <Box sx={{ pt: 2, px: 2, pb: 2 }}>
            <TextField
              key={openModal}
              style={{ width: "100%" }}
              size="small"
              placeholder="Search"
              onKeyPress={handleKeyPress}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#D7D1D1" }} />
                  </InputAdornment>
                ),
                sx: {
                  color: "#D7D1D1",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: 6,
                  "& .MuiOutlinedInput-notchedOutline": {},
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: " 2px solid #7F6C6C",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: " 2px solid #7F6C6C",
                  },
                  height: "35px",
                },
              }}
              autoComplete="off" // Add this line to turn off auto-fill
            />
          </Box>

          <Box>
            <Button
              disableRipple
              class="custom-button"
              onClick={() => {
                navigate("/usd-collections");
                toggleDrawer("left", false)(); // Close the drawer
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Shop NFT's
              </Typography>
            </Button>
          </Box>
          <Box>
            <Button
              disableRipple
              class="custom-button"
              onClick={handleExploreClick}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Explore
                <ArrowDropDownIcon className="icon" />
              </Typography>
            </Button>

            <Menu
              disableRipple
              className="menu"
              anchorEl={exploreAnchorEl}
              open={Boolean(exploreAnchorEl)}
              onClose={handleExploreClose}
            >
              <MenuItem onClick={handleNavigationForSale} sx={{ px: 6, py: 2 }}>
                Recently for Sale
              </MenuItem>
              <MenuItem onClick={handleCampaignsClick} sx={{ px: 6, py: 2 }}>
                Campaigns
              </MenuItem>
              <MenuItem onClick={handleUSDtoXRPClick} sx={{ px: 6, py: 2 }}>
                USD Collections
              </MenuItem>
              <MenuItem onClick={handleCollectionsClick} sx={{ px: 6, py: 2 }}>
                XRP Collections
              </MenuItem>
              <MenuItem
                onClick={handleCollectionsGameClick}
                sx={{ px: 6, py: 2 }}
              >
                GAME Collections
              </MenuItem>
              {/* <MenuItem onClick={handleExploreClose} sx={{ px: 4, py: 1 }}>
                    Logout
                  </MenuItem> */}
            </Menu>
          </Box>

          <Box>
            <Button
              disableRipple
              class="custom-button"
              onClick={handlePacksClick}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Packs
                <ArrowDropDownIcon className="icon" />
              </Typography>
            </Button>

            <Menu
              disableRipple
              className="menu"
              anchorEl={packsAnchorEl}
              open={Boolean(packsAnchorEl)}
              onClose={handlePacksClose}
            >
              <MenuItem onClick={handleBuyPacks} sx={{ px: 6, py: 2 }}>
                Buy Packs
              </MenuItem>
              <MenuItem onClick={handleCampaignsClick} sx={{ px: 6, py: 2 }}>
                Open Packs
              </MenuItem>
              <MenuItem onClick={handlePackNftsClick} sx={{ px: 6, py: 2 }}>
                Pack NFTs
              </MenuItem>
            </Menu>
          </Box>

          <Box>
            <Button
              disableRipple
              class="custom-button"
              onClick={handleAccountClick}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Account
                <ArrowDropDownIcon className="icon" />
              </Typography>
            </Button>
            <Menu
              className="menu"
              anchorEl={accountAnchorEl}
              open={Boolean(accountAnchorEl)}
              onClose={handleAccountClose}
            >
              <MenuItem
                onClick={handleNavigationMyNfts}
                sx={{
                  px: 6,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: "purple", // Default purple background for MenuItem
                  color: "white", // Default white text for MenuItem
                  "&:hover": {
                    backgroundColor: "#aeaeae !important", // Change background on hover
                  },
                }}
              >
                My NFTs
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    backgroundColor: "purple", // Default purple background for nftCounts
                    color: "white", // Default white text for nftCounts
                  }}
                >
                  {allCounts?.total_nfts || 0}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={handleNavigationMinting}
                sx={{
                  px: 6,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: "purple", // Default purple background for MenuItem
                  color: "white", // Default white text for MenuItem
                  "&:hover": {
                    backgroundColor: "#aeaeae !important", // Change background on hover
                  },
                }}
              >
                My Listed NFTs
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    backgroundColor: "purple", // Default purple background for nftCounts
                    color: "white", // Default white text for nftCounts
                  }}
                >
                  {allCounts?.sellOffers || 0}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountClose();
                  navigate("nfts/offers");
                  toggleDrawer("left", false)(); // Close the drawer
                }}
                sx={{
                  px: 6,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: "purple", // Default purple background for MenuItem
                  color: "white", // Default white text for MenuItem
                  "&:hover": {
                    backgroundColor: "#aeaeae !important", // Change background on hover
                  },
                }}
              >
                My Offers
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    backgroundColor: "purple", // Default purple background for nftCounts
                    color: "white", // Default white text for nftCounts
                  }}
                >
                  {allCounts?.mybuyOffers || 0}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountClose();
                  navigate("nfts/approvals");
                  toggleDrawer("left", false)();
                  handleAccountClose();
                }}
                sx={{
                  px: 6,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: "purple", // Default purple background for MenuItem
                  color: "white", // Default white text for MenuItem
                  "&:hover": {
                    backgroundColor: "#aeaeae !important", // Change background on hover
                  },
                }}
              >
                Waiting My Approval
                <Box
                  sx={{
                    ml: 1,
                    position: "relative",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    backgroundColor: "purple", // Default purple background for nftCounts
                    color: "white", // Default white text for nftCounts
                  }}
                >
                  {allCounts?.mybuyOffersOnMyNFT || 0}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountClose();
                  navigate("nfts/transfer");
                  toggleDrawer("left", false)();
                  toast.error("You need to login first to access NFTs");
                  handleAccountClose();
                }}
                sx={{
                  px: 6,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  // backgroundColor: "purple", // Default purple background for MenuItem
                  color: "white", // Default white text for MenuItem
                  "&:hover": {
                    backgroundColor: "#aeaeae !important", // Change background on hover
                  },
                }}
              >
                Transfer
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    backgroundColor: "purple", // Default purple background for nftCounts
                    color: "white", // Default white text for nftCounts
                  }}
                >
                  {allCounts?.TransferOffers || 0}
                </Box>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleAccountClose();
                  navigate("/nfts/packclaims");
                  toggleDrawer("left", false)();
                  handleAccountClose();
                }}
                sx={{
                  px: 6,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#aeaeae !important",
                  },
                }}
              >
                Claim Pack NFTs
                <Box
                  sx={{
                    ml: 1,
                    position: "relative",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    backgroundColor: "purple", // Default purple background for nftCounts
                    color: "white", // Default white text for nftCounts
                  }}
                >
                  {allCounts?.packNfts || 0}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountClose();
                  navigate("nfts/favorite");
                  toggleDrawer("left", false)();
                  handleAccountClose();
                }}
                sx={{ px: 6, py: 2 }}
              >
                Favorite
              </MenuItem>
            </Menu>
          </Box>

          {isAdmin ? (
            <Box>
              <Button
                disableRipple
                class="custom-button"
                onClick={handleAdminClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  Admin
                  <ArrowDropDownIcon className="icon" />
                </Typography>
              </Button>
              <Menu
                className="menu"
                anchorEl={adminAnchorEl}
                open={Boolean(adminAnchorEl)}
                onClose={handleAdminClose}
                // sx={{ p: 6 }}
              >
                <MenuItem
                  onClick={handleNavigationCarousel}
                  sx={{ px: 6, py: 2 }}
                >
                  Collection
                </MenuItem>
                <MenuItem onClick={handleNavigationAdmin} sx={{ px: 6, py: 2 }}>
                  Admin
                </MenuItem>
                <MenuItem
                  onClick={handleNavigationAdminFeatured}
                  sx={{ px: 6, py: 2 }}
                >
                  Featured
                </MenuItem>

                <MenuItem
                  onClick={handleNavigationMinting}
                  sx={{ px: 6, py: 2 }}
                >
                  Mint
                </MenuItem>

                <MenuItem
                  onClick={handleNavigationCategory}
                  sx={{ px: 6, py: 2 }}
                >
                  Category
                </MenuItem>
                <MenuItem onClick={handleBulkMintDetail} sx={{ px: 6, py: 2 }}>
                  Bulk Mint Detail
                </MenuItem>
                {/* <MenuItem onClick={handleNavigationFeatured}>Featured</MenuItem> */}
              </Menu>
            </Box>
          ) : (
            <Box>
              <Button
                disableRipple
                class="custom-button"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  navigate("/about");
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  About
                </Typography>
              </Button>
            </Box>
          )}

          <Box
            className="submenu"
            style={{
              ...submenuStyle,
              ...(openExplore ? openSubmenuStyle : {}),
            }}
          >
            {/* <List>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/submenu1">
                  <ListItemText primary="Submenu Item 1" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/submenu2">
                  <ListItemText primary="Submenu Item 2" />
                </ListItemButton>
              </ListItem>
            </List> */}
          </Box>

          {/* This Box will position the Login component at the bottom center */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end", // Push content to the bottom
              alignItems: "center", // Center content horizontally
              height: "100%", // Take full height of the Drawer
              pb: 2, // Optional padding at the bottom
            }}
          >
            <Login drawer={false} />
          </Box>
        </Drawer>
      </Box>

      <Dialog
        open={openModal}
        onClose={handleClose}
        fullWidth
        maxWidth="md" // Increase the maxWidth for a wider dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#1a1a1a", // Dark black for the body background
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: "#333",
            color: "white",
            display: "flex",
            justifyContent: "space-between", // Space between the items
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "1.6rem" }}>
            Search
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: "white",
              backgroundColor: "#AF22C8",
              border: "3px solid white",
              borderRadius: "50%",
              // Adjust icon size by changing the fontSize
              fontSize: "1rem", // Smaller icon size
            }}
          >
            <CloseIcon sx={{ fontSize: "inherit" }} />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ color: "white" }}>
          {loading ? (
            <CustomLoader width={"60%"} text={"Please wait ..."} />
          ) : (
            <>
              {/* LOOKING FOR */}
              {(collection && collection.length > 0) ||
              (nfts && nfts.length > 0) ? (
                <>
                  <Typography
                    gutterBottom
                    variant="h5"
                    sx={{ color: "yellow" }}
                  >
                    What are you looking for?
                  </Typography>
                  <Typography gutterBottom>
                    Find your favorite collections and NFTs using our search
                    tool. A fuzzy search will be carried out - For example, if
                    you searched for 'xdu', your search would bring back results
                    for collections and NFTs whose name or decription contain
                    XDU - For example, xDudes
                  </Typography>
                </>
              ) : null}
              {/* LOOKING FOR */}

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.8rem", // Increased font size
                  mt: 3,
                  color: "#AF22C8",
                }}
              >
                <AccountBalanceWalletIcon
                  sx={{ mr: 1, color: "inherit", fontSize: "inherit" }}
                />{" "}
                NFT Results
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.25rem", color: "#AF22C8" }}
              >
                <Grid container spacing={2}>
                  {!nfts || nfts.length === 0 ? (
                    <Box
                      sx={{
                        pt: 6,
                        p: 2,
                      }}
                    >
                      <Typography variant="h6">No NFT's Found!</Typography>
                    </Box>
                  ) : (
                    nfts?.map((items) => (
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        key={items.id}
                      >
                        <SearchCard
                          data={items}
                          forSale={false}
                          isFeatured={isFeatured}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.8rem", // Increased font size
                  mt: 3,
                  color: "#AF22C8",
                }}
              >
                <CollectionsIcon
                  sx={{ mr: 1, color: "inherit", fontSize: "inherit" }}
                />{" "}
                Collection Results
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.25rem", color: "#AF22C8" }}
              >
                <Grid container spacing={2} sx={{ display: "flex" }}>
                  {!collection || collection.lenght == 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        pt: 6,
                        p: 2,
                      }}
                    >
                      <Typography variant="h6">No Collection Found!</Typography>
                    </Box>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 2, paddingInline: 4, justifyContent: "center" }}
                    >
                      <MediaCard data={collection} onConfirm={handleData} />
                    </Grid>
                  )}
                </Grid>
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CustomDrawer;
